<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Cadastro Aprovação
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatarDataExtenso()"></small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div [ngClass]="{'col-sm-12':showAprovacoes,'col-sm-8':!showAprovacoes}">
      <div class="shadow-box" [ngClass]="false ? 'box-fullscreen' : ''">
        <div class="headerBox">
          <ul class="list-inline filter-menu mb-3">
            <li class="list-inline-item" [ngClass]="{ active: showAprovadores }">
              <a (click)="changeState('showAprovadores')">Aprovadores</a>
            </li>
            <li class="list-inline-item" [ngClass]="{ active: showAlcadas }">
              <a (click)="changeState('showAlcadas')">Alçadas</a>
            </li>
            <li class="list-inline-item" [ngClass]="{ active: showAprovacoes }">
              <a (click)="changeState('showAprovacoes')">Aprovações</a>
            </li>
          </ul>
          <div class="d-flex">
            <app-toolbar [value]="toolbarAprov"></app-toolbar>
          </div>
        </div>
        <div class="box-content">
          <p-table #dt1 *ngIf="showAprovacoes" class="table-system" [value]="tableAprovacoes" dataKey="id"
            selectionMode="single" (onRowSelect)="onRowSelect($event)" (onRowReorder)="onRowReorder()"
            (onRowUnselect)="onRowUnselect()" [reorderableColumns]="true" [(selection)]="selectedApprov">

            <ng-template pTemplate="header">
              <tr>
                <th style="width: 10%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'ordem'">
                    ordem <p-sortIcon [field]="'ordem'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'ordem', 'contains')">
                </th>
                <th style="width: 5%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'codigo'">
                    codigo <p-sortIcon [field]="'codigo'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'codigo', 'contains')">
                </th>
                <th style="width: 15%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'descricao'">
                    descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                </th>
                <th style="width: 10%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'condicao'">
                    alçada <p-sortIcon [field]="'condicao'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'condicao', 'contains')">
                </th>
                <th style="width: 15%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'aprovador'">
                    aprovador <p-sortIcon [field]="'aprovador'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'aprovador', 'contains')">
                </th>
                <th style="width: 15%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'destinoAprov'">
                    dest aprov <p-sortIcon [field]="'destinoAprov'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'destinoAprov', 'contains')">
                </th>
                <th style="width: 15%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'destinoRec'">
                    dest reprov <p-sortIcon [field]="'destinoRec'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'destinoRec', 'contains')">
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-index="rowIndex" let-expanded="expanded">
              <tr [pReorderableRow]="index" [pSelectableRow]="rowData" class="line" [pRowToggler]="rowData">
                <td>
                  <div class="d-flex">
                    <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                    <span class="overflow-ellipse text-center" style="margin-left: 35%;">
                      {{rowData.ordem}}
                    </span>
                  </div>
                </td>
                <td>
                  <span class="overflow-ellipse text-center">
                    {{rowData.codigo}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-center">
                    {{(rowData.descricao)}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-center">
                    {{rowData.condicao}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-center">
                    {{rowData.aprovador}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-center">
                    {{rowData.destinoAprov}}
                    {{rowData.proxAprovId!=null?'('+convertId(rowData.proxAprovId,'aprovacao')+')':''}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-center">
                    {{rowData.destinoRec}}
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="7" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhuma Aprovação Encontrada!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <p-table #dt2 *ngIf="showAprovadores" class="table-system" [value]="tableAprovadores" dataKey="id"
            selectionMode="single" (onRowSelect)="onRowSelect($event)" (onRowReorder)="onRowReorder()"
            (onRowUnselect)="onRowUnselect()">

            <ng-template pTemplate="header">
              <tr>
                <th style="width: 30%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'nomeAprovador'">
                    aprovador <p-sortIcon [field]="'nomeAprovador'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'nomeAprovador', 'contains')">
                </th>
                <th style="width: 30%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'nomeSubstituto'">
                    substituto <p-sortIcon [field]="'nomeSubstituto'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'nomeSubstituto', 'contains')">
                </th>
                <th style="width: 15%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'dtIni'">
                    data Ini <p-sortIcon [field]="'dtIni'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'dtIni', 'contains')">
                </th>
                <th style="width: 15%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'dtFin'">
                    data Fin <p-sortIcon [field]="'dtFin'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'dtFin', 'contains')">
                </th>
                <th style="width: 10%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'ativo'">
                    ativo <p-sortIcon [field]="'ativo'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'ativo', 'contains')">
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData" class="line">
                <td>
                  <span class="overflow-ellipse text-center">
                    {{rowData.nomeAprovador}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-center">
                    {{rowData.nomeSubstituto}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-left">
                    {{rowData.dtIni}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-left">
                    {{rowData.dtFin}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-center">
                    <p-checkbox [binary]="true" [disabled]="true" class="checkBox" [ngModel]="rowData.ativo">
                    </p-checkbox>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="5" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Aprovador Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <p-table #dt3 *ngIf="showAlcadas" class="table-system" [value]="tableAlcadas" dataKey="id"
            selectionMode="single" (onRowSelect)="onRowSelect($event)" (onRowReorder)="onRowReorder()"
            (onRowUnselect)="onRowUnselect()" [reorderableColumns]="true">

            <ng-template pTemplate="header">
              <tr>
                <th style="width: 20%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'codigo'">
                    codigo <p-sortIcon [field]="'codigo'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'codigo', 'contains')">
                </th>
                <th style="width: 45%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'descricao'">
                    descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'descricao', 'contains')">
                </th>
                <th style="width: 10%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'valorMin'">
                    valor Min <p-sortIcon [field]="'valorMin'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'valorMin', 'contains')">
                </th>
                <th style="width: 10%;">
                  <span class="overflow-ellipse text-center" [pSortableColumn]="'valorMax'">
                    valor Max <p-sortIcon [field]="'valorMax'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'valorMax', 'contains')">
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-index="rowIndex" let-expanded="expanded">
              <tr [pReorderableRow]="index" [pSelectableRow]="rowData" class="line" [pRowToggler]="rowData">
                <td>
                  <span class="overflow-ellipse text-center">
                    {{rowData.codigo}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-center">
                    {{rowData.descricao}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-rigth">
                    {{rowData.valorMin}}
                  </span>
                </td>
                <td>
                  <span class="overflow-ellipse text-rigth">
                    {{rowData.valorMax}}
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="4" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhuma Alçada Encontrada!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

    </div>
    <div *ngIf="aprovDetails" [ngClass]="{'col-sm-12 mt-3':showAprovacoes,'col-sm-4':!showAprovacoes}">
      <div class="shadow-box">
        <div class="headerBox">
          <label class="titleCard">Detalhes</label>
          <div class="d-flex">
            <button *ngIf="!showDetails" class="btnCustom-green" (click)="onClickUpdate()">
              <span class="material-icons">save</span>
              <div>Salvar</div>
            </button>
          </div>
        </div>
        <div class="box-content">
          <div class="d-flex flex-column">

            <div *ngIf="showAprovacoes" class="d-flex flex-column col-sm-12">

              <main class="d-flex">

                <div class="form-group col-sm-2">
                  <label>Código</label>
                  <input type="text" class="text-left form-control" [(ngModel)]="selectedAprovacoes.codigo" />
                </div>

                <div class="form-group col-sm-3">
                  <label>Descrição</label>
                  <span class="obrigatorio" *ngIf="!selectedAprovacoes.descricao">*</span>
                  <input type="text" class="text-left form-control" [(ngModel)]="selectedAprovacoes.descricao" />
                </div>
                <main class="d-flex justify-content-between" style="width:100%;">
                  <div class="form-checkbox">
                    <label class="overflow-ellipse">Exigir rateio</label>
                    <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAprovacoes.exigeRateio">
                    </p-checkbox>
                  </div>
                  <div class="form-checkbox">
                    <label class="overflow-ellipse">Exigir Participante</label>
                    <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAprovacoes.exigeParticipante">
                    </p-checkbox>
                  </div>
                  <div class="form-checkbox">
                    <label class="overflow-ellipse">Exigir Forma de pagamento</label>
                    <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAprovacoes.exigeFormaPagamento">
                    </p-checkbox>
                  </div>
                  <div class="form-checkbox">
                    <label class="overflow-ellipse">Exigir item</label>
                    <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAprovacoes.exigeItem">
                    </p-checkbox>
                  </div>
                  <div class="form-checkbox">
                    <label class="overflow-ellipse">Exigir Operação</label>
                    <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAprovacoes.exigeOperacao">
                    </p-checkbox>
                  </div>
                  <div class="form-checkbox">
                    <label class="overflow-ellipse">Exigir fornecedor escolhido</label>
                    <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAprovacoes.exigeFornecedorEscolhido">
                    </p-checkbox>
                  </div>
                  <div class="form-checkbox">
                    <label class="overflow-ellipse">Gerencia rascunho</label>
                    <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAprovacoes.gerenciaRascunho">
                    </p-checkbox>
                  </div>
                  <div class="form-checkbox">
                    <label class="overflow-ellipse">Completa RMS</label>
                    <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAprovacoes.completaRms">
                    </p-checkbox>
                  </div>
                </main>
              </main>

              <p-table class="table-system" [value]="tableRelacAprovOrigemDestino" selectionMode="single"
                (onRowSelect)="enableEditOrigemDestino($event)" (onRowUnselect)="disableEditOrigemDestino($event)"
                [(selection)]="selectOD">
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <span class="overflow-ellipse text-center">
                        agrupar
                      </span>
                    </th>
                    <th>
                      <span class="overflow-ellipse text-center">
                        origem
                      </span>
                    </th>
                    <th>
                      <span class="overflow-ellipse text-center">
                        condição
                      </span>
                    </th>
                    <th>
                      <span class="overflow-ellipse text-center">
                        alçada
                      </span>
                    </th>
                    <th>
                      <span class="overflow-ellipse text-center">
                        aprovador
                      </span>
                    </th>
                    <th>
                      <span class="overflow-ellipse text-center">
                        destino aprovado
                      </span>
                    </th>
                    <th>
                      <span class="overflow-ellipse text-center">
                        destino reprovado
                      </span>
                    </th>
                    <th style="width: 65px;">
                      <span class="overflow-ellipse text-center">
                        ação
                      </span>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr class="line" [pSelectableRow]="rowData">
                    <td>
                      <span class="overflow-ellipse text-center">
                        <p-checkbox [binary]="true" [disabled]="true" class="checkBox" [ngModel]="rowData.agrupar">
                        </p-checkbox>
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-center">
                        {{rowData.origemId==4?convertId(rowData.aprovacaoAnteriorId,'aprovacao'):convertId(rowData.origemId,
                        'origem')}}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-center">
                        {{rowData.sql}}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-center">
                        {{convertId(rowData.alcadaId, 'alcada')}}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-center" matTooltip="{{getTooltip(rowData.listAprovadorId)}}"
                        matTooltipPosition="above" matTooltipClass="my-tooltip"
                        [matTooltipDisabled]="!rowData.aprovacaoConj">
                        {{rowData.aprovacaoConj?'Vários':convertId(rowData.aprovadorId, 'aprovador')}}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-center">
                        {{rowData.destinoAprovId!=1?convertId(rowData.destinoAprovId,'destinoAprov'):''}}
                        {{rowData.proxAprovId!=null?convertId(rowData.proxAprovId,'aprovacao'):''}}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-center">
                        {{rowData.destinoRecusaId!=3?convertId(rowData.destinoRecusaId, 'destinoRecusa'):''}}
                        {{rowData.proxRecusaId!=null?convertId(rowData.proxRecusaId,'aprovacao'):''}}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-center">
                        <span matTooltip="Remover" class="material-icons" style="font-size: 16px;"
                          (click)="removeOrigemDestino(rowData)">delete</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="8" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Fluxo vazio!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>

              <div class="w-100 d-flex mt-3 justify-content-start">
                <button *ngIf="isNewOrigemDestino" type="button" class="btn btn-sm btn-info"
                  (click)="newOrigemDestino()">
                  <i class="fas fa-plus"></i> Adicionar
                </button>
              </div>

              <div *ngIf="showDetails">

                <div class="d-flex mt-3">
                  <div class="col-sm-1 form-checkbox">
                    <label>Conjunta</label>
                    <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedRelac.aprovacaoConj"
                      (onChange)="changeAprovacaoConj()">
                    </p-checkbox>
                  </div>
                  <div class="form-group col-sm-4">
                    <label>{{selectedRelac.aprovacaoConj?'Aprovadores':'Aprovador'}}</label>
                    <p-multiSelect *ngIf="selectedRelac.aprovacaoConj" class="type-p-mult" appendTo="body"
                      [filter]="true" defaultLabel="Selecione os Aprovadores "
                      [options]="getAprovadorList(selectedRelac.aprovacaoConj)" (onChange)="verificaAlcadaAprovador()"
                      [(ngModel)]="selectedRelac.listAprovadorId">
                    </p-multiSelect>
                    <p-dropdown *ngIf="!selectedRelac.aprovacaoConj" class="type-p-mult" appendTo="body" [filter]="true"
                      defaultLabel="Selecione o Aprovador" [options]="getAprovadorList(selectedRelac.aprovacaoConj)"
                      (onChange)="verificaAlcadaAprovador()" [(ngModel)]="selectedRelac.aprovadorId">
                    </p-dropdown>
                  </div>
                  <div class="form-group col-sm-4">
                    <label>Alçada</label>
                    <p-dropdown class="type-p-mult" appendTo="body" [filter]="true" defaultLabel="Selecione a Alçada"
                      (onChange)="verificaAlcadaAprovador()" [options]="alcadaList"
                      [(ngModel)]="selectedRelac.alcadaId">
                    </p-dropdown>
                  </div>
                  <div class="col-sm-1 form-checkbox">
                    <label>Agrupar</label>
                    <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedRelac.agrupar">
                    </p-checkbox>
                  </div>
                </div>

                <main class="d-flex col-sm-12 mt-3 mb-1">
                  <main class="d-flex flex-column origemWrapper mr-2">
                    <label class="titleCard mb-2">Origem</label>
                    <p-dropdown class="type-p-mult mb-3" appendTo="body" [filter]="true"
                      placeholder="Selecione a origem" [options]="opcoesOrigem" [(ngModel)]="selectedRelac.origemId"
                      (onChange)="selectedRelac.aprovacaoAnteriorId = null; selectedRelac.sql = relacOrigemView[selectedRelac.origemId] == null? '' : selectedRelac.sql">
                    </p-dropdown>
                    <p-dropdown class="type-p-mult mb-3" appendTo="body" [filter]="true"
                      *ngIf="selectedRelac.origemId == 4" defaultLabel="Informe a aprovação" [options]="opcoesAprovacao"
                      [(ngModel)]="selectedRelac.aprovacaoAnteriorId">
                    </p-dropdown>
                    <div [ngClass]="{'hidden':relacOrigemView[selectedRelac.origemId] == null}">
                      <p-table class="table-system" #dt [value]="getTableView()" selectionMode="single" [rows]="10"
                        [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage">
                        <ng-template pTemplate="header">
                          <tr>
                            <th>
                              <span class="overflow-ellipse text-center">
                                campo
                              </span>
                              <input class="form-control form-control-sm" type="text"
                                (input)="dt.filter($event.target.value, 'campo', 'contains')">
                            </th>
                            <th>
                              <span class="overflow-ellipse text-center">
                                descrição
                              </span>
                              <input class="form-control form-control-sm" type="text"
                                (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                            </th>
                            <th>
                              <span class="overflow-ellipse text-center">
                                tipo
                              </span>
                              <input class="form-control form-control-sm" type="text"
                                (input)="dt.filter($event.target.value, 'tipo', 'contains')">
                            </th>
                          </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-rowData>
                          <tr [pSelectableRow]="rowData" (click)="onClickViewCampo(rowData.campo)">
                            <td>
                              <span class="overflow-ellipse text-center">
                                {{rowData.campo}}
                              </span>
                            </td>
                            <td>
                              <span class="overflow-ellipse text-center">
                                {{rowData.descricao}}
                              </span>
                            </td>
                            <td>
                              <span class="overflow-ellipse text-left">
                                {{rowData.tipo}}
                              </span>
                            </td>
                          </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td [attr.colspan]="3" class="spaced-alert">
                              <div class="alert alert-xs alert-primary d-inline" role="alert">
                                <i class="fas fa-info-circle"></i> Nenhuma Informação Encontrada!
                              </div>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>

                      <div class="form-group mt-2 col-sm-12">
                        <mark class="d-inline-block mb-1" style="font-size: 13px;">
                          Não informar o <strong>WHERE</strong>, somente a condição.
                        </mark>
                        <textarea rows="8" cols="100" [(ngModel)]="selectedRelac.sql" class="form-control"></textarea>
                      </div>

                    </div>
                  </main>
                  <p-divider layout="vertical"></p-divider>
                  <main class="d-flex flex-column origemWrapper ml-2">
                    <label class="titleCard mb-3">Destino</label>
                    <div class="d-flex flex-column mt-3">
                      <p-divider align="left" type="dashed">
                        <b>Aprovado</b>
                      </p-divider>
                      <div class="d-flex">
                        <div class="form-group col-sm-8">
                          <label>Ação</label>
                          <p-dropdown class="type-p-mult" appendTo="body" [filter]="true"
                            defaultLabel="Selecione o destino" [options]="opcoesDestinoAprov"
                            (onChange)="selectedRelac.proxAprovId = null" [(ngModel)]="selectedRelac.destinoAprovId">
                          </p-dropdown>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="form-group col-sm-8">
                          <label>Próxima etapa</label>
                          <p-dropdown class="type-p-mult" appendTo="body" [filter]="true"
                            [disabled]="convertId(selectedRelac.destinoAprovId, 'destinoAprov').toLowerCase() != 'passar para outra etapa'"
                            defaultLabel="Selecione a etapa" [options]="opcoesAprovacao"
                            [(ngModel)]="selectedRelac.proxAprovId">
                          </p-dropdown>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-column"
                      *ngIf="!selectedRelac.alcadaId || ((!selectedRelac.aprovacaoConj && selectedRelac.aprovadorId) || selectedRelac.aprovacaoConj && selectedRelac.listAprovadorId && selectedRelac.listAprovadorId.length > 0 && selectedRelac.listAprovadorId[0])">
                      <p-divider align="left" type="dashed">
                        <b>Recusado</b>
                      </p-divider>
                      <div class="d-flex">
                        <div class="form-group col-sm-8">
                          <label>Ação</label>
                          <p-dropdown class="type-p-mult" appendTo="body" [filter]="true"
                            defaultLabel="Selecione o destino" [options]="opcoesDestinoRecusa"
                            (onChange)="selectedRelac.proxRecusaId = null" [(ngModel)]="selectedRelac.destinoRecusaId">
                          </p-dropdown>
                        </div>
                      </div>
                      <div class="d-flex">
                        <div class="form-group col-sm-8">
                          <label>Próxima etapa</label>
                          <p-dropdown class="type-p-mult" appendTo="body" [filter]="true"
                            [disabled]="convertId(selectedRelac.destinoRecusaId, 'destinoRecusa').toLowerCase() != 'passar para outra etapa'"
                            defaultLabel="Selecione a etapa" [options]="opcoesAprovacao"
                            [(ngModel)]="selectedRelac.proxRecusaId">
                          </p-dropdown>
                        </div>
                      </div>
                    </div>
                  </main>
                </main>

                <div class="headerBox justify-content-start">
                  <button type="button" class="btn btn-sm btn-info" (click)="addOrigemDestino()">
                    <i class="fas fa-save"></i> Salvar
                  </button>
                  <button type="button" class="btn btn-sm btn-info" (click)="cancelOrigemDestino()">
                    <i class="fas fa-times"></i> Cancelar
                  </button>
                </div>

              </div>
            </div>

            <div *ngIf="showAprovadores">

              <div class="d-flex align-items-center mb-3">
                <span class="col-sm-4 text-left overflow-ellipse mr-1">
                  <label>Aprovador</label>
                  <span class="obrigatorio" *ngIf="!selectedAprovadores.authUser.id">*</span>
                </span>
                <div class="col-sm-8">
                  <p-dropdown class="type-p-mult" appendTo="body" [filter]="true" defaultLabel="Selecione o Aprovador"
                    [options]="getAuthUserList(selectedAprovadores.authUser.id)"
                    [(ngModel)]="selectedAprovadores.authUser.id">
                  </p-dropdown>
                </div>
              </div>

              <div class="d-flex align-items-center mb-3">
                <span class="col-sm-4 text-left overflow-ellipse mr-1">
                  <label>Substituto</label>
                </span>
                <div class="col-sm-8">
                  <p-dropdown class="type-p-mult" appendTo="body" [filter]="true" defaultLabel="Selecione o Aprovador"
                    [options]="getAprovadorList(false)" [(ngModel)]="selectedAprovadores.aprovadorSubstituto.id">
                  </p-dropdown>
                </div>
              </div>

              <div class="d-flex align-items-center mb-3">
                <span class="col-sm-4 text-left overflow-ellipse mr-1"><label>Data Ini</label></span>
                <div class="col-sm-8">
                  <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy"
                    [(ngModel)]="selectedAprovadores.dtIni" [monthNavigator]="true" [yearNavigator]="true"
                    yearRange="2010:2030">
                  </p-calendar>
                </div>
              </div>

              <div class="d-flex align-items-center mb-3">
                <span class="col-sm-4 text-left overflow-ellipse mr-1"><label>Data Fin</label></span>
                <div class="col-sm-8">
                  <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy"
                    [(ngModel)]="selectedAprovadores.dtFin" [monthNavigator]="true" [yearNavigator]="true"
                    yearRange="2010:2030">
                  </p-calendar>
                </div>
              </div>

              <div class="d-flex align-items-center mb-3">
                <span class="col-sm-4 text-left overflow-ellipse mr-1"><label>Ativo</label></span>
                <div class="col-sm-8">
                  <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAprovadores.ativo">
                  </p-checkbox>
                </div>
              </div>

            </div>

            <div *ngIf="showAlcadas">

              <div class="d-flex mb-3">
                <div class="form-group col-sm-4">
                  <label>Código</label>
                  <input type="text" class="text-left form-control" [(ngModel)]="selectedAlcadas.codigo" />
                </div>
                <div class="form-group col-sm-8">
                  <label>Descrição</label>
                  <span class="obrigatorio" *ngIf="!selectedAlcadas.descricao">*</span>
                  <input type="text" class="text-left form-control" [(ngModel)]="selectedAlcadas.descricao" />
                </div>
              </div>

              <!-- <div class="mb-3">
                <div class="form-group col-sm-12">
                  <label>Rateio</label>
                  <div class="d-flex">
                    <div class="col-sm-6" style="padding-left: 0px;">
                      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true"
                        placeholder="Selecione um Centro de Custo" [options]="opcoesCentroCustos"
                        [(ngModel)]="selectedAlcadas.rateio.ccusId"
                        (onChange)="changeCcus(selectedAlcadas.rateio.ccusId)">
                      </p-dropdown>
                    </div>
                    <div class="col-sm-6" style="padding-right: 0px;">
                      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true"
                        placeholder="Selecione um Projeto" [options]="opcoesProjetos"
                        [(ngModel)]="selectedAlcadas.rateio.projId">
                      </p-dropdown>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <div class="form-group col-sm-12">
                  <div class="d-flex">
                    <div class="col-sm-6" style="padding-left: 0px;">
                      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true"
                        placeholder="Selecione uma Área" [options]="opcoesArea"
                        [(ngModel)]="selectedAlcadas.rateio.areaId"
                        (onChange)="changeArea(selectedAlcadas.rateio.areaId)">
                      </p-dropdown>
                    </div>
                    <div class="col-sm-6" style="padding-right: 0px;">
                      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true"
                        placeholder="Selecione uma Subarea" [options]="opcoesSubarea"
                        [(ngModel)]="selectedAlcadas.rateio.subareaId">
                      </p-dropdown>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="d-flex">
                <div class="form-checkbox col-sm-4">
                  <label>Valor Min Ativo</label>
                  <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAlcadas.hasValorMin"
                    (onChange)="selectedAlcadas.valorMin = null"></p-checkbox>
                </div>
                <div class="form-group col-sm-6" *ngIf="selectedAlcadas.hasValorMin">
                  <label>Valor Min</label>
                  <input type="number" class="text-left form-control" [(ngModel)]="selectedAlcadas.valorMin" />
                </div>
              </div>

              <div class="d-flex">
                <div class="form-checkbox col-sm-4">
                  <label>Valor Max Ativo</label>
                  <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAlcadas.hasValorMax"
                    (onChange)="selectedAlcadas.valorMax = null"></p-checkbox>
                </div>
                <div class="form-group col-sm-6" *ngIf="selectedAlcadas.hasValorMax">
                  <label>Valor Max</label>
                  <input type="number" class="text-left form-control" [(ngModel)]="selectedAlcadas.valorMax" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="aprovDetails" [ngClass]="{'col-sm-12 mt-3':showAprovacoes,'col-sm-4':!showAprovacoes}">
      <div class="shadow-box">
        <div class="headerBox">
          <label class="titleCard">Envio de E-mail</label>
          <div class="d-flex">
          </div>
        </div>
        <div class="box-content">
          <div class="d-flex flex-column">
            <div *ngIf="showAprovacoes" class="d-flex flex-column col-sm-12">
              <main class="d-flex" style="flex-wrap: wrap;">
                <div class="form-group col-sm-2">
                  <label>Dias em espera</label>
                  <input type="number" class="text-left form-control" [(ngModel)]="selectedAprovacoes.intervaloDias"/>
                </div>
                <div class="form-group col-sm-6">
                  <label>Título</label>
                  <input type="text" class="text-left form-control" [(ngModel)]="selectedAprovacoes.titulo"/>
                </div>
                <div class="form-group col-sm-3">
                  <label>Destinatário</label>
                  <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione um destinatário"
                    [options]="optionsDestinatarios" [(ngModel)]="selectedAprovacoes.envioFornecedor">
                  </p-dropdown>
                </div>
                <div class="form-checkbox col-sm-1">
                  <label class="ml-4">Ativo</label>
                  <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="selectedAprovacoes.ativo"></p-checkbox>
                </div>
                <div class="form-group col-sm-12">
                  <label>Descrição</label>
                  <p-editor [(ngModel)]="selectedAprovacoes.templateHtml"></p-editor>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-dialog [header]="cadModalTitle" [(visible)]="cadModal" [style]="{'width':'600px'}" [modal]="true"
    styleClass="modal-custom" [responsive]="true" appendTo="body" [closable]="true">

    <div class="d-flex flex-column">

      <main *ngIf="showAprovacoes">

        <div class="d-flex">
          <div class="d-flex flex-column col-sm-8">
            <label>Código</label>
            <input type="text" class="text-left form-control" [(ngModel)]="newAprovValues.codigo" />
          </div>
        </div>

        <div class="form-group col-sm-12">
          <label>Descrição</label>
          <span class="obrigatorio" *ngIf="!newAprovValues.descricao">*</span>
          <input type="text" class="text-left form-control" [(ngModel)]="newAprovValues.descricao" />
        </div>

      </main>

      <main *ngIf="showAprovadores">

        <div class="d-flex">
          <div class="form-group col-sm-6">
            <label>Aprovador</label>
            <span class="obrigatorio" *ngIf="!newAprovadores.authUserId">*</span>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione um Aprovador"
              [options]="getAuthUserList()" [(ngModel)]="newAprovadores.authUserId">
            </p-dropdown>
          </div>

          <div class="form-group col-sm-6">
            <label>Substituto</label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione um Substituto"
              [options]="getAprovadorList(false)" [(ngModel)]="newAprovadores.aprovadorSubstitutoId">
            </p-dropdown>
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group col-sm-5">
            <label>Data Ini</label>
            <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="newAprovadores.dtIni"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030">
            </p-calendar>
          </div>
          <div class="form-group col-sm-5">
            <label>Data Fin</label>
            <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="newAprovadores.dtFin"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030">
            </p-calendar>
          </div>
          <div class="d-flex flex-column col-sm-2">
            <label>Ativo</label>
            <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="newAprovadores.ativo"></p-checkbox>
          </div>
        </div>

      </main>

      <main *ngIf="showAlcadas">

        <div class="d-flex">
          <div class="form-group col-sm-4">
            <label>Código</label>
            <input type="text" class="text-left form-control" [(ngModel)]="newAlcadas.codigo" />
          </div>
          <div class="form-group col-sm-8">
            <label>Descrição</label>
            <span class="obrigatorio" *ngIf="!newAlcadas.descricao">*</span>
            <input type="text" class="text-left form-control" [(ngModel)]="newAlcadas.descricao" />
          </div>
        </div>

        <!-- <div class="form-group col-sm-12">
          <label>Rateio</label>
          <div class="d-flex justify-content-between">
            <p-dropdown appendTo="body" class="type-p-dropdown dropdownAlcada" [filter]="true"
              placeholder="Selecione um Centro de Custo" [options]="opcoesCentroCustos"
              [(ngModel)]="newAlcadas.rateio.ccusId" (onChange)="changeCcus(newAlcadas.rateio.ccusId)">
            </p-dropdown>
            <p-dropdown appendTo="body" class="type-p-dropdown dropdownAlcada" [filter]="true"
              placeholder="Selecione um Projeto" [options]="opcoesProjetos" [(ngModel)]="newAlcadas.rateio.projId">
            </p-dropdown>
          </div>
        </div>

        <div class="form-group col-sm-12">
          <div class="d-flex justify-content-between">
            <p-dropdown appendTo="body" class="type-p-dropdown dropdownAlcada" [filter]="true"
              placeholder="Selecione uma Área" [options]="opcoesArea" [(ngModel)]="newAlcadas.rateio.areaId"
              (onChange)="changeArea(newAlcadas.rateio.areaId)">
            </p-dropdown>
            <p-dropdown appendTo="body" class="type-p-dropdown dropdownAlcada" [filter]="true"
              placeholder="Selecione uma Subarea" [options]="opcoesSubarea" [(ngModel)]="newAlcadas.rateio.subareaId">
            </p-dropdown>
          </div>
        </div> -->

        <div class="d-flex">
          <div class="form-checkbox col-sm-6">
            <label>Valor Min Ativo</label>
            <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="newAlcadas.hasValorMin"></p-checkbox>
          </div>
          <div class="form-group col-sm-6" *ngIf="newAlcadas.hasValorMin">
            <label>Valor Min</label>
            <input type="number" class="text-left form-control" [(ngModel)]="newAlcadas.valorMin" />
          </div>
        </div>

        <div class="d-flex">
          <div class="form-checkbox col-sm-6">
            <label>Valor Max Ativo</label>
            <p-checkbox [binary]="true" class="checkBox" [(ngModel)]="newAlcadas.hasValorMax"></p-checkbox>
          </div>
          <div class="form-group col-sm-6" *ngIf="newAlcadas.hasValorMax">
            <label>Valor Max</label>
            <input type="number" class="text-left form-control" [(ngModel)]="newAlcadas.valorMax" />
          </div>
        </div>

      </main>

    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="cadModal=false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button type="button" class="btn btn-primary" (click)="onClickSaveCad()">
        <i class="fas fa-check"></i> Criar
      </button>
    </p-footer>

  </p-dialog>